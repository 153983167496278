import React from 'react'
import { Remark, RemarkProps } from 'react-remark'
import styled from 'styled-components'

interface IProps {
  children: string
  customMdComponents?: RemarkProps['rehypeReactOptions']['components']
}

export const Markdown: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  customMdComponents,
}) => (
  <Remark rehypeReactOptions={{ components: customMdComponents ?? defaultMdComponents }}>
    {children}
  </Remark>
)

const MdBold = styled.span`
  ${({ theme }) => theme.typo.bold}
`
const MdLink = styled.a`
  color: ${({ theme }) => theme.color.terracota};

  ${({ theme }) => theme.typo.link}
`
const MdParagraph = styled.p`
  margin-bottom: ${({ theme }) => theme.spacing.xs};
`
const MdListUl = styled.ul`
  margin: ${({ theme }) => theme.spacing.xs} 0;
  padding-left: ${({ theme }) => theme.spacing.m};
`
const MdListLi = styled.li`
  &::before {
    color: ${({ theme }) => theme.color.terracota};
    content: '•';
    display: inline-block;
    margin-left: -1em;
    width: 1em;
  }
`
export const defaultMdComponents = {
  a: MdLink,
  p: MdParagraph,
  ul: MdListUl,
  li: MdListLi,
  strong: MdBold,
}
