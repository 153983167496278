import { IAreaKey } from '@/types/main'

export type ILessonFiltersState = {
  pageIndex: number
  startAt: string
  endAt: string
  areaKey: IAreaKey
  postalCodes: number[]
  placeIds: number[]
  teacherIds: number[]
  timeslotIds: number[]
  durationIds: number[]
  levelIds: number[]
  disciplineIds: number[]
  objectiveIds: number[]
  serieUuid: string | null
  tag: string | null
}
export type ILessonFilterKeys = keyof ILessonFiltersState

export const lessonFiltersInitialState: ILessonFiltersState = {
  pageIndex: 1,
  startAt: null,
  endAt: null,
  areaKey: null,
  postalCodes: [],
  placeIds: [],
  teacherIds: [],
  timeslotIds: [],
  durationIds: [],
  levelIds: [],
  disciplineIds: [],
  objectiveIds: [],
  serieUuid: null,
  tag: null,
}

export type ILessonFiltersAction =
  | { type: 'resetAll'; initialLessonFiltersState?: ILessonFiltersState }
  | { type: 'overwriteSome'; overwrittenFilters: Partial<ILessonFiltersState> }
  | { type: 'setPageIndex'; setPageIndex: number }
  | {
      type: 'setDatesRange'
      datesRange: {
        startAt: string
        endAt: string
      }
    }
  | { type: 'setArea'; areaKey: IAreaKey | null }
  | { type: 'addPostalCode'; addPostalCode: number }
  | { type: 'rmPostalCode'; rmPostalCode: number }
  | { type: 'togglePostalCode'; togglePostalCode: number }
  | { type: 'setPostalCodes'; setPostalCodes: number[] }
  | { type: 'addPlace'; addPlaceId: number }
  | { type: 'rmPlace'; rmPlaceId: number }
  | { type: 'togglePlace'; togglePlaceId: number }
  | { type: 'setPlaces'; setPlaceIds: number[] }
  | { type: 'addTeacher'; addTeacherId: number }
  | { type: 'rmTeacher'; rmTeacherId: number }
  | { type: 'toggleTeacher'; toggleTeacherId: number }
  | { type: 'setTeachers'; setTeacherIds: number[] }
  | { type: 'addTimeslot'; addTimeslotId: number }
  | { type: 'rmTimeslot'; rmTimeslotId: number }
  | { type: 'toggleTimeslot'; toggleTimeslotId: number }
  | { type: 'setTimeslots'; setTimeslotIds: number[] }
  | { type: 'addDuration'; addDurationId: number }
  | { type: 'rmDuration'; rmDurationId: number }
  | { type: 'toggleDuration'; toggleDurationId: number }
  | { type: 'setDurations'; setDurationIds: number[] }
  | { type: 'addLevel'; addLevelId: number }
  | { type: 'rmLevel'; rmLevelId: number }
  | { type: 'toggleLevel'; toggleLevelId: number }
  | { type: 'setLevels'; setLevelIds: number[] }
  | { type: 'addDiscipline'; addDisciplineId: number }
  | { type: 'rmDiscipline'; rmDisciplineId: number }
  | { type: 'toggleDiscipline'; toggleDisciplineId: number }
  | { type: 'setDisciplines'; setDisciplineIds: number[] }
  | { type: 'addObjective'; addObjectiveId: number }
  | { type: 'rmObjective'; rmObjectiveId: number }
  | { type: 'toggleObjective'; toggleObjectiveId: number }
  | { type: 'setObjectives'; setObjectiveIds: number[] }
  | { type: 'setSerieUuid'; serieUuid: string | null }
  | { type: 'setTag'; tag: string | null }

export type ILessonFiltersDispatch = (action: ILessonFiltersAction) => void

export const lessonFiltersReducer = (
  state: ILessonFiltersState,
  action: ILessonFiltersAction
): ILessonFiltersState => {
  switch (action.type) {
    case 'resetAll':
      return {
        ...(action.initialLessonFiltersState ?? lessonFiltersInitialState),
      }
    case 'overwriteSome': {
      return {
        ...state,
        ...action.overwrittenFilters,
      }
    }
    case 'setPageIndex':
      return {
        ...state,
        pageIndex: action.setPageIndex,
      }
    case 'setDatesRange':
      return {
        ...state,
        startAt: action.datesRange.startAt,
        endAt:
          new Date(action.datesRange.endAt) < new Date(action.datesRange.startAt)
            ? action.datesRange.startAt
            : action.datesRange.endAt,
        pageIndex: 1, // Reset page
      }
    case 'setArea':
      return {
        ...state,
        areaKey: action.areaKey,
        pageIndex: 1, // Reset page
      }
    case 'addPostalCode':
      if (state.postalCodes.includes(action.addPostalCode)) {
        return state
      }

      return {
        ...state,
        postalCodes: [...state.postalCodes, action.addPostalCode],
        pageIndex: 1, // Reset page
      }
    case 'rmPostalCode':
      return {
        ...state,
        postalCodes: state.postalCodes.filter(
          (postalCodeKey) => postalCodeKey !== action.rmPostalCode
        ),
        pageIndex: 1, // Reset page
      }
    case 'togglePostalCode':
      if (state.postalCodes.includes(action.togglePostalCode)) {
        return {
          ...state,
          postalCodes: state.postalCodes.filter(
            (postalCodeKey) => postalCodeKey !== action.togglePostalCode
          ),
          pageIndex: 1, // Reset page
        }
      }

      return {
        ...state,
        postalCodes: [...state.postalCodes, action.togglePostalCode],
        pageIndex: 1, // Reset page
      }
    case 'setPostalCodes':
      return {
        ...state,
        postalCodes: action.setPostalCodes,
        pageIndex: 1, // Reset page
      }
    case 'addPlace':
      if (state.placeIds.includes(action.addPlaceId)) {
        return state
      }

      return {
        ...state,
        placeIds: [...state.placeIds, action.addPlaceId],
        pageIndex: 1, // Reset page
      }
    case 'rmPlace':
      return {
        ...state,
        placeIds: state.placeIds.filter((placeId) => placeId !== action.rmPlaceId),
        pageIndex: 1, // Reset page
      }
    case 'togglePlace':
      if (state.placeIds.includes(action.togglePlaceId)) {
        return {
          ...state,
          placeIds: state.placeIds.filter((placeId) => placeId !== action.togglePlaceId),
          pageIndex: 1, // Reset page
        }
      }

      return {
        ...state,
        placeIds: [...state.placeIds, action.togglePlaceId],
        pageIndex: 1, // Reset page
      }
    case 'setPlaces':
      return {
        ...state,
        placeIds: action.setPlaceIds,
        pageIndex: 1, // Reset page
      }
    case 'addTeacher':
      if (state.teacherIds.includes(action.addTeacherId)) {
        return state
      }

      return {
        ...state,
        teacherIds: [...state.teacherIds, action.addTeacherId],
        pageIndex: 1, // Reset page
      }
    case 'rmTeacher':
      return {
        ...state,
        teacherIds: state.teacherIds.filter(
          (teacherId) => teacherId !== action.rmTeacherId
        ),
        pageIndex: 1, // Reset page
      }
    case 'toggleTeacher':
      if (state.teacherIds.includes(action.toggleTeacherId)) {
        return {
          ...state,
          teacherIds: state.teacherIds.filter(
            (teacherId) => teacherId !== action.toggleTeacherId
          ),
          pageIndex: 1, // Reset page
        }
      }

      return {
        ...state,
        teacherIds: [...state.teacherIds, action.toggleTeacherId],
        pageIndex: 1, // Reset page
      }
    case 'setTeachers':
      return {
        ...state,
        teacherIds: action.setTeacherIds,
        pageIndex: 1, // Reset page
      }
    case 'addTimeslot':
      if (state.timeslotIds.includes(action.addTimeslotId)) {
        return state
      }

      return {
        ...state,
        timeslotIds: [...state.timeslotIds, action.addTimeslotId],
        pageIndex: 1, // Reset page
      }
    case 'rmTimeslot':
      return {
        ...state,
        timeslotIds: state.timeslotIds.filter(
          (timeslotId) => timeslotId !== action.rmTimeslotId
        ),
        pageIndex: 1, // Reset page
      }
    case 'toggleTimeslot':
      if (state.timeslotIds.includes(action.toggleTimeslotId)) {
        return {
          ...state,
          timeslotIds: state.timeslotIds.filter(
            (timeslotId) => timeslotId !== action.toggleTimeslotId
          ),
          pageIndex: 1, // Reset page
        }
      }

      return {
        ...state,
        timeslotIds: [...state.timeslotIds, action.toggleTimeslotId],
        pageIndex: 1, // Reset page
      }
    case 'setTimeslots':
      return {
        ...state,
        timeslotIds: action.setTimeslotIds,
        pageIndex: 1, // Reset page
      }

    case 'addDuration':
      if (state.durationIds.includes(action.addDurationId)) {
        return state
      }

      return {
        ...state,
        durationIds: [...state.durationIds, action.addDurationId],
        pageIndex: 1, // Reset page
      }
    case 'rmDuration':
      return {
        ...state,
        durationIds: state.durationIds.filter(
          (durationId) => durationId !== action.rmDurationId
        ),
        pageIndex: 1, // Reset page
      }
    case 'toggleDuration':
      if (state.durationIds.includes(action.toggleDurationId)) {
        return {
          ...state,
          durationIds: state.durationIds.filter(
            (durationId) => durationId !== action.toggleDurationId
          ),
          pageIndex: 1, // Reset page
        }
      }

      return {
        ...state,
        durationIds: [...state.durationIds, action.toggleDurationId],
        pageIndex: 1, // Reset page
      }
    case 'setDurations':
      return {
        ...state,
        durationIds: action.setDurationIds,
        pageIndex: 1, // Reset page
      }
    case 'addLevel':
      if (state.levelIds.includes(action.addLevelId)) {
        return state
      }

      return {
        ...state,
        levelIds: [...state.levelIds, action.addLevelId],
        pageIndex: 1, // Reset page
      }
    case 'rmLevel':
      return {
        ...state,
        levelIds: state.levelIds.filter((levelKey) => levelKey !== action.rmLevelId),
        pageIndex: 1, // Reset page
      }
    case 'toggleLevel':
      if (state.levelIds.includes(action.toggleLevelId)) {
        return {
          ...state,
          levelIds: state.levelIds.filter(
            (levelKey) => levelKey !== action.toggleLevelId
          ),
          pageIndex: 1, // Reset page
        }
      }

      return {
        ...state,
        levelIds: [...state.levelIds, action.toggleLevelId],
        pageIndex: 1, // Reset page
      }
    case 'setLevels':
      return {
        ...state,
        levelIds: action.setLevelIds,
        pageIndex: 1, // Reset page
      }
    case 'addDiscipline':
      if (state.disciplineIds.includes(action.addDisciplineId)) {
        return state
      }

      return {
        ...state,
        disciplineIds: [...state.disciplineIds, action.addDisciplineId],
        pageIndex: 1, // Reset page
      }
    case 'rmDiscipline':
      return {
        ...state,
        disciplineIds: state.disciplineIds.filter(
          (disciplineKey) => disciplineKey !== action.rmDisciplineId
        ),
        pageIndex: 1, // Reset page
      }
    case 'toggleDiscipline':
      if (state.disciplineIds.includes(action.toggleDisciplineId)) {
        return {
          ...state,
          disciplineIds: state.disciplineIds.filter(
            (disciplineKey) => disciplineKey !== action.toggleDisciplineId
          ),
          pageIndex: 1, // Reset page
        }
      }

      return {
        ...state,
        disciplineIds: [...state.disciplineIds, action.toggleDisciplineId],
        pageIndex: 1, // Reset page
      }
    case 'setDisciplines':
      return {
        ...state,
        disciplineIds: action.setDisciplineIds,
        pageIndex: 1, // Reset page
      }
    case 'addObjective':
      if (state.objectiveIds.includes(action.addObjectiveId)) {
        return state
      }

      return {
        ...state,
        objectiveIds: [...state.objectiveIds, action.addObjectiveId],
        pageIndex: 1, // Reset page
      }
    case 'rmObjective':
      return {
        ...state,
        objectiveIds: state.objectiveIds.filter(
          (objectiveKey) => objectiveKey !== action.rmObjectiveId
        ),
        pageIndex: 1, // Reset page
      }
    case 'toggleObjective':
      if (state.objectiveIds.includes(action.toggleObjectiveId)) {
        return {
          ...state,
          objectiveIds: state.objectiveIds.filter(
            (objectiveKey) => objectiveKey !== action.toggleObjectiveId
          ),
          pageIndex: 1, // Reset page
        }
      }

      return {
        ...state,
        objectiveIds: [...state.objectiveIds, action.toggleObjectiveId],
        pageIndex: 1, // Reset page
      }
    case 'setObjectives':
      return {
        ...state,
        objectiveIds: action.setObjectiveIds,
        pageIndex: 1, // Reset page
      }
    case 'setSerieUuid':
      return {
        ...state,
        serieUuid: action.serieUuid,
        pageIndex: 1, // Reset page
      }
    case 'setTag':
      return {
        ...state,
        tag: action.tag,
        pageIndex: 1, // Reset page
      }
    default:
      return state
  }
}
