import { KeyedMutator, SWRConfiguration } from 'swr'

import {
  getPlaceResults,
  IPlaceDecoratedGQLResults,
  IPlaceGQLResults,
  IPlaceResultsVariables,
} from '@/services/api-graphql-place'
import { IPlace } from '@/types/main'
import { HTTPError } from '@/utils/api-helpers'
import { decoratePlace } from '@/utils/decorator'
import { buildGenericSwrMutate } from '@/utils/swr'

import { useSWRAuth } from './useSwr-auth'
import { useSWRCustom } from './useSwr-custom'

export const SWR_KEY__PLACE_RESULTS = 'place_results'

interface IReturnType {
  placeListLoading: boolean
  placeList: IPlaceDecoratedGQLResults
  placeListError: HTTPError
  placeListValidating: boolean
  mutatePlaceList: KeyedMutator<IPlaceGQLResults>
}

export const useSWRPlaceResults = (
  gqlVariables: IPlaceResultsVariables = {},
  swrConfig: SWRConfiguration = { revalidateOnFocus: false }
): IReturnType => {
  const { payload } = useSWRAuth()
  const { data, error, mutate, isLoading, isValidating } = useSWRCustom<IPlaceGQLResults>(
    [gqlVariables.areaKey && SWR_KEY__PLACE_RESULTS, payload?.uuid],
    getPlaceResults,
    {
      ...gqlVariables,
      itemsPerPage: 400,
    },
    swrConfig
  )

  return {
    placeListLoading: isLoading,
    placeList: data && decoratePlaceList(data, mutate),
    placeListError: error,
    placeListValidating: isValidating,
    mutatePlaceList: mutate,
  }
}

const decoratePlaceList = (
  placeResults: IPlaceGQLResults,
  mutatePlaceList: IReturnType['mutatePlaceList']
): IPlaceDecoratedGQLResults => {
  // Custom place.mutate that proxy change on mutatePlaceList
  const mutate = buildGenericSwrMutate(mutatePlaceList)

  const placeDecoratedCollection = placeResults.collection.map((place) =>
    decoratePlace(place, mutate)
  )

  return {
    ...placeResults,
    collection: placeDecoratedCollection,
  }
}
