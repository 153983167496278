import styled, { css } from 'styled-components'

import { Markdown } from '@/components/_shared/Markdown'
import { DEVICE_WIDTH } from '@/utils/constants'

export const StyledH1 = styled.h1`
  ${({ theme }) => theme.typo.h1}

  margin: ${({ theme }) => theme.spacing.ms} 0;
  text-align: center;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    margin: ${({ theme }) => theme.spacing.l} 0;
  }
`

const descriptionStyle = css`
  color: ${({ theme }) => theme.color.grey};
  margin: 0 auto;
  text-align: center;
`

export const StyledDescription = styled.p`
  ${descriptionStyle}

  max-width: 684px;
`

export const StyledMarkdownDescription = styled(Markdown)`
  ${descriptionStyle}
`
