import React, { createContext, useContext, useReducer } from 'react'

import {
  ILessonFiltersAction,
  ILessonFiltersDispatch,
  ILessonFiltersState,
  lessonFiltersReducer,
} from '@/reducers/lessonFilters'

export interface ILessonFiltersStateContext {
  lessonFiltersState: ILessonFiltersState
  initialLessonFiltersState: ILessonFiltersState
}

export const LessonFiltersStateContext = createContext<
  ILessonFiltersStateContext | undefined
>(undefined)

export const LessonFiltersDispatchContext = createContext<
  ILessonFiltersDispatch | undefined
>(undefined)

interface IProps {
  initialLessonFiltersState: ILessonFiltersState
}

export const LessonFiltersContextProvider: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  initialLessonFiltersState,
}) => {
  const [lessonFiltersState, lessonFiltersDispatch] = useReducer(
    lessonFiltersReducer,
    initialLessonFiltersState
  )

  // Extend lessonFiltersDispatch fn to use initialLessonFiltersState on resetAll action
  const lessonFiltersDispatchExtended = (action: ILessonFiltersAction) => {
    if (action.type !== 'resetAll') {
      return lessonFiltersDispatch(action)
    }

    return lessonFiltersDispatch({ type: 'resetAll', initialLessonFiltersState })
  }

  return (
    <LessonFiltersStateContext.Provider
      value={{ lessonFiltersState, initialLessonFiltersState }}
    >
      <LessonFiltersDispatchContext.Provider value={lessonFiltersDispatchExtended}>
        {children}
      </LessonFiltersDispatchContext.Provider>
    </LessonFiltersStateContext.Provider>
  )
}

export const useLessonFiltersState = (): ILessonFiltersStateContext => {
  const context = useContext(LessonFiltersStateContext)

  if (context === undefined) {
    throw new Error(
      'useLessonFiltersState must be called inside a subtree of LessonFiltersProvider'
    )
  }

  return context
}

export const useLessonFiltersDispatch = (): ILessonFiltersDispatch => {
  const context = useContext(LessonFiltersDispatchContext)

  if (context === undefined) {
    throw new Error(
      'useLessonFiltersDispatch must be called inside a subtree of LessonFiltersProvider'
    )
  }

  return context
}

export const useLessonFilters = (): [
  ILessonFiltersStateContext,
  ILessonFiltersDispatch
] => [useLessonFiltersState(), useLessonFiltersDispatch()]
