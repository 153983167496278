import React from 'react'
import ContentLoader from 'react-content-loader'
import styled from 'styled-components'

import { DEVICE_WIDTH } from '@/utils/constants'

import { LessonListFilterSkeleton } from './LessonListFilterSkeleton'

const BUTTON_HEIGHT = 48

export const LessonListFiltersLoading: React.FC = () => (
  <div>
    <MobileWrapper>
      <StyledContentLoader uniqueKey="LessonListFiltersLoading">
        <rect
          x="0"
          y="0"
          rx={BUTTON_HEIGHT / 2}
          ry={BUTTON_HEIGHT / 2}
          width="100%"
          height={BUTTON_HEIGHT}
        />
      </StyledContentLoader>
    </MobileWrapper>

    <DesktopWrapper>
      {[...Array(7)].map((x, i) => (
        <LessonListFilterSkeleton key={i} />
      ))}
    </DesktopWrapper>
  </div>
)

const MobileWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xs};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    display: none;
  }
`
const DesktopWrapper = styled.div`
  display: none;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    display: block;
    display: flex;
    justify-content: flex-start;
    margin-bottom: ${({ theme }) => theme.spacing.ms};

    & > *:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing.s};
    }
  }
`
const StyledContentLoader = styled(ContentLoader)`
  height: ${BUTTON_HEIGHT}px;
  width: 100%;
`
