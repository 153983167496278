import React from 'react'

import { PropertyRemovableSelectionList } from '@/components/_shared/filters/PropertyRemovableSelectionList'
import { RemovableSelectionList } from '@/components/_shared/filters/RemovableSelectionList'
import { ILessonFilterKeys, ILessonFiltersDispatch } from '@/reducers/lessonFilters'
import { IPlaceGQLResults } from '@/services/api-graphql-place'
import { ITeacherGQLResults } from '@/services/api-graphql-teacher'
import { IPropertyKey, IPropertyValueArea } from '@/types/main'

interface IProps {
  filterKey: ILessonFilterKeys
  filterValues: (string | number)[]
  lessonFiltersDispatch: ILessonFiltersDispatch
  propertyList: IPropertyKey[]
  areaChildrenList: IPropertyValueArea[]
  placeList?: IPlaceGQLResults
  teacherList?: ITeacherGQLResults
}

export const GenericRemovableSelectionList: React.FC<IProps> = ({
  filterKey,
  filterValues,
  lessonFiltersDispatch,
  propertyList,
  areaChildrenList,
  placeList,
  teacherList,
}) => {
  switch (filterKey) {
    case 'disciplineIds':
      return (
        <PropertyRemovableSelectionList
          propKey={propertyList.find((propKey) => propKey.slug === 'discipline')}
          selectedValues={filterValues}
          onRemoveFn={(value: number) =>
            lessonFiltersDispatch({ type: 'rmDiscipline', rmDisciplineId: value })
          }
        />
      )
    case 'objectiveIds':
      return (
        <PropertyRemovableSelectionList
          propKey={propertyList.find((propKey) => propKey.slug === 'user-objective')}
          selectedValues={filterValues}
          onRemoveFn={(value: number) =>
            lessonFiltersDispatch({ type: 'rmObjective', rmObjectiveId: value })
          }
        />
      )
    case 'postalCodes':
      return (
        <RemovableSelectionList
          options={areaChildrenList.map((areaChild) => ({
            label: areaChild.title,
            value: areaChild.misc.postalCode,
          }))}
          filterValues={filterValues}
          onRemoveFn={(value: number) =>
            lessonFiltersDispatch({
              type: 'rmPostalCode',
              rmPostalCode: value,
            })
          }
        />
      )
    case 'timeslotIds':
      return (
        <PropertyRemovableSelectionList
          propKey={propertyList.find((propKey) => propKey.slug === 'timeslot')}
          selectedValues={filterValues}
          onRemoveFn={(value: number) =>
            lessonFiltersDispatch({ type: 'rmTimeslot', rmTimeslotId: value })
          }
        />
      )
    case 'durationIds':
      return (
        <PropertyRemovableSelectionList
          propKey={propertyList.find((propKey) => propKey.slug === 'duration-lesson')}
          selectedValues={filterValues}
          onRemoveFn={(value: number) =>
            lessonFiltersDispatch({ type: 'rmDuration', rmDurationId: value })
          }
        />
      )
    case 'placeIds':
      return (
        placeList && (
          <RemovableSelectionList
            options={placeList.collection.map(({ _id, title }) => ({
              label: title,
              value: _id,
            }))}
            filterValues={filterValues}
            onRemoveFn={(value: number) =>
              lessonFiltersDispatch({
                type: 'rmPlace',
                rmPlaceId: value,
              })
            }
          />
        )
      )
    case 'teacherIds':
      return (
        teacherList && (
          <RemovableSelectionList
            options={teacherList.collection.map(({ _id, name }) => ({
              label: name,
              value: _id,
            }))}
            filterValues={filterValues}
            onRemoveFn={(value: number) =>
              lessonFiltersDispatch({
                type: 'rmTeacher',
                rmTeacherId: value,
              })
            }
          />
        )
      )
    case 'levelIds':
      return (
        <PropertyRemovableSelectionList
          propKey={propertyList.find((propKey) => propKey.slug === 'level')}
          selectedValues={filterValues}
          onRemoveFn={(value: number) =>
            lessonFiltersDispatch({ type: 'rmLevel', rmLevelId: value })
          }
        />
      )
    default:
      return null
  }
}
